export const en = {
  form: {
    signup: 'Sign Up',
    signupSuccess: 'You have successfully signed up!',
    signupSuccessDescription: 'Please check your email to confirm your account.',
    signin: 'Sign In',
    signout: 'Sign Out',
    emailVerified: 'Email verified!',
    emailVerifiedDescription: 'You can now sign in.',
    verifyEmail: 'Verify email',
    verifyEmailDescription: 'You need to verify your email before you can sign in.',
    verificationError: 'Verification error',
    invalidTokenDescription: 'The verification link is invalid or has already been used.',
    expiredTokenDescription: 'The verification link has expired.',
    resentSuccess: 'Verification email resent!',
    resentSuccessDescription: 'Please check your email to confirm your account.',
    alreadyHaveAnAccount: 'Already have an account? Sign in',
    dontHaveAnAccount: "Don't have an account? Sign up",
    stayLoggedIn: 'Stay logged in',
    email: { label: 'Email' },
    password: {
      label: 'Password',
      confirmLabel: 'Confirm password',
      show: 'Show password',
      hide: 'Hide password',
    },
    name: 'Name',
    search: 'Search',
    enterTerm: 'Enter a term',
    noResults: 'No results',
    loading: 'Loading...',
    privacyPolicy: {
      disclaimerPrefix: 'I have read and agree to the ',
      disclaimerLink: 'privacy policy',
      disclaimerSuffix: '.',
    },
    fieldError: {
      required: 'This field is required.',
      invalidEmail: 'Please enter a valid email.',
      passwordTooShort: 'Please enter a password with at least 8 characters.',
      passwordsDontMatch: 'The passwords do not match.',
      accountExists: 'There is already an account with this email address.',
      somethingWentWrong: 'Something went wrong. Please try again.',
      invalidCredentials: 'Invalid email or password.',
      acceptPrivacyPolicy: 'Please accept the privacy policy.',
      missingQuestion: 'Please enter a question.',
      missingOptions: 'Please add at least one option.',
      missingOption: 'Please fill in or remove the option.',
      missingAnswer: 'Please enter an answer.',
      missingAnswerSelection: 'Please select an answer.',
      missingLat: 'Please enter a lat.',
      missingLng: 'Please enter a lng.',
      missingUnscrambleEqual: 'The scrambled word should not be equal to the answer.',
      missingWord: 'Please enter a word.',
      nonNumericAnswer: 'Please enter a number.',
      invalidLat: 'Please enter a valid lat, between -90 and 90.',
      invalidLng: 'Please enter a valid lng, between -180 and 180.',
      invalidNanoId:
        'Please note that the ID must be between 3 and 12 characters in length and may only contain alphanumeric characters (a-z, A-Z, 0-9), as well as hyphens (-).',
      nanoIdNotAvailable: 'This ID is already in use.',
      invalidPassword: 'Invalid password.',
      playerNameExists: 'There is already a player with this name.',
      invalidName:
        'The name can only contain letters (a-z and A-Z) and numbers (0-9) and must be between 2 and 24 characters in length.',
      invalidFile: 'Please select a valid file that is under 10MB in size.',
      invalidWordLength: 'The word must be between 2 and 8 characters in length.',
      verifyEmail: 'You need to verify your email before you can sign in.',
    },
    question: {
      question: 'Question',
      type: 'Type',
      answer: 'Answer',
      option: 'Option',
      solution: 'Solution',
      word: 'Word',
      lat: 'Latitude',
      lng: 'Longitude',
      scrambled: 'Scrambled word',
      unit: 'Unit',
      asset: 'Image/Audio/Video (currently only visible on the presentation)',
    },
    game: {
      title: {
        label: 'Title',
        description: 'Enter a title for the game. This will be displayed to the players.',
      },
      nanoId: {
        label: 'Game ID',
        description:
          'This is the unique identifier of the game. It will be used to join the game. You can leave it empty to generate a random one.',
      },
      scheduledDate: {
        label: 'Date and time',
        description:
          'If you want to schedule the game, you can enter the date and time here. Otherwise, leave it empty.',
      },
      password: {
        label: 'Password',
        description:
          'If you want to protect your game with a password, you can enter it here. Otherwise, leave it empty.',
      },
    },
  },
  error: {
    generic: {
      title: 'The application has encountered an unexpected error.',
      description:
        "It doesn't appear to have affected your data, but our technical staff have been automatically notified and will be looking into this with the utmost urgency.",
    },
  },
  questionType: {
    label: 'Question type',
    choice: 'Multiple choice',
    open: 'Open ended',
    closest: 'Closest wins',
    geo: 'Find the spot',
    unscramble: 'Unscramble the word',
    reorder: 'Reorder it',
    pixel: 'Low resolution',
    time: 'Time estimation',
    reflexes: 'Fastest finger',
  },
  action: {
    new: 'New',
    add: 'Add',
    create: 'Create',
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Remove',
    delete: 'Delete',
    edit: 'Edit',
    sort: 'Sort',
    newGame: 'New game',
    markAsCorrect: 'Mark as correct',
    markedAsCorrect: 'Marked as correct',
    addOption: 'Add option',
    next: 'Next',
    back: 'Back',
    play: 'Play',
    abort: 'Abort',
    abortGame: 'Abort game',
    showAnswer: 'Show answer',
    submitAnswer: 'Submit answer',
    nextQuestion: 'Next question',
    startGame: 'Start game',
    endGame: 'End game',
    join: 'Join',
    unlock: 'Unlock',
    openPresentation: 'Open presentation',
    givePoints: 'Give points',
    removePoints: 'Remove points',
    resendVerificationEmail: 'Resend verification email',
    grantAudioPermission: 'Grant audio permission',
    selectFile: 'Select file',
    startTimer: 'Start timer',
  },
  page: {
    features: 'Features',
    pricing: 'Pricing',
    about: 'About',
    dashboard: 'Dashboard',
    quizzes: 'Quizzes',
    questions: 'Questions',
    settings: 'Settings',
    signin: 'Sign In',
    signup: 'Sign Up',
    resetPassword: 'Reset Password',
    games: 'Games',
  },
  modal: {
    saveChanges: {
      title: 'You have unsaved changes.',
      description: 'Your changes will be lost if you do not save them.',
    },
    createQuiz: {
      title: 'Create new quiz',
      description: 'Please enter a name for your new quiz.',
    },
    deleteQuiz: {
      title: 'Are you sure you want to delete this quiz?',
      description: 'This action cannot be undone.',
    },
    deleteQuestion: {
      title: 'Are you sure you want to delete this question?',
      description: 'This action cannot be undone.',
      cascade: 'The question will be removed from following quizzes:',
    },
    createQuestion: {
      title: 'Create new question',
      description:
        'Add a new question to your question catalogue by filling out the fields below. Start by selecting a question type. Then, provide the question prompt and customize the answer options as needed.',
    },
    editQuestion: {
      title: 'Edit question',
    },
    createSlot: {
      title: 'Add new question, category or break',
    },
    createGame: {
      title: 'Create and start a new game',
    },
    abortGame: {
      title: 'Are you sure you want to abort this game?',
      description:
        "Please be aware that taking this action will permanently end the game for all players. However, the game's unique identifier will be made available for reuse by other users, but the game will be saved for later review.",
    },
  },
  questions: {
    uppercase: 'Questions',
    lowercase: 'questions',
  },
  question: {
    uppercase: 'Question',
    lowercase: 'question',
  },
  quizzes: {
    uppercase: 'Quizzes',
    lowercase: 'quizzes',
  },
  quiz: {
    uppercase: 'Quiz',
    lowercase: 'quiz',
  },
  games: {
    uppercase: 'Games',
    lowercase: 'games',
  },
  title: 'Title',
  name: 'Name',
  score: 'Score',
  type: 'Type',
  created: 'Created',
  uses: 'Uses',
  nanoId: 'ID',
  status: 'Status',
  password: 'Password',
  scheduledDate: 'Start',
  players: 'Players',
  content: 'Content',
  noPlayers: 'No players yet',
  yourAnswer: 'Your answer',
  player: 'Player',
  answer: 'Answer',
  finalScore: 'Final Score',
  tab: {
    catalogue: 'Catalogue',
    question: 'New question',
    break: 'Category or break',
  },
  gameStatus: {
    waiting: 'Waiting',
    running: 'Running',
    aborted: 'Aborted',
    finished: 'Finished',
  },
  hint: {
    scheduledDate: 'The scheduled start time for this game is in',
    gameStartIn: 'The game will start in',
    waitingForHost: 'Waiting for the host to start the game.',
    startGame: (
      <>
        Press <strong>Start game</strong> to start the game and show the first question to the players.
      </>
    ),
    passwordProtected: 'This game is password protected. Please enter the password to join the game.',
    howToJoin: 'Scan the QR code or enter the URL in your browser.',
    abortedGame: 'This game has been aborted. Please contact the host for more information.',
    audioNotAllowed:
      'This quiz has some questions that come with video or audio elements, but your browser blocks them by default. Please click the button below to ensure that these elements can be played with sound. If this message persists, please check your browser settings.',
  },
  needAudioPermission: "You need to grant audio permission to play this quiz's audio.",
};
