import classNames from 'classnames';

import { AlertCircle, CheckCircle, XCircle } from '../misc/icons';

type AlertProps = {
  title: string;
  description: React.ReactNode;
  type?: 'success' | 'warning' | 'error';
  className?: string;
};

const Alert = ({ title, description, type = 'error', className }: AlertProps) => {
  return (
    <div
      className={classNames(
        'rounded-xl p-4',
        {
          'bg-green-100': type === 'success',
          'bg-orange-100': type === 'warning',
          'bg-red-100': type === 'error',
        },
        className
      )}
    >
      <div className="flex">
        {type === 'success' && <CheckCircle className="mt-0.5 flex-shrink-0 text-2xl text-green-700" />}
        {type === 'warning' && <AlertCircle className="mt-0.5 flex-shrink-0 text-2xl text-orange-700" />}
        {type === 'error' && <XCircle className="mt-0.5 flex-shrink-0 text-2xl text-red-700" />}
        <div className="ml-3">
          <h3
            className={classNames('text-lg font-bold', {
              'text-green-700': type === 'success',
              'text-orange-700': type === 'warning',
              'text-red-700': type === 'error',
            })}
          >
            {title}
          </h3>
          <div
            className={classNames({
              'text-green-700': type === 'success',
              'text-orange-700': type === 'warning',
              'text-red-700': type === 'error',
            })}
          >
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
