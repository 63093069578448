import get from 'lodash/get';
import { useRouter } from 'next/router';

import { de } from './de';
import { en } from './en';

type DefaultTranslationEntry = typeof en;
type TranslationEntries = Partial<DefaultTranslationEntry>;
type TranslationProps = Record<string, TranslationEntries>;

export const languages: TranslationProps = {
  en,
  de,
};

export const useTranslation = () => {
  const { locale = 'en' } = useRouter();
  const fallbackEnabled = false;

  const translate = (key: string) => {
    let translation = languages[locale] && get(languages[locale], key);

    if (!translation && fallbackEnabled) {
      translation = languages['en'] && get(languages['en'], key);
    }

    if (!translation) {
      console.log(`Missing translation for key: ${key}`);
      // return '[ MISSING TRANSLATION ]';
      return key;
    }

    return translation;
  };

  return { translate, locale };
};
