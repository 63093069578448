import { cva } from 'class-variance-authority';
import classNames from 'classnames';
import { Icon } from 'src/types';

import LoadingIndicator from './LoadingIndicator';

type BaseProps = {
  color?: 'primary' | 'dark' | 'gray' | 'white' | 'green' | 'orange' | 'red';
  iconPrefix?: Icon;
  iconSuffix?: Icon;
  loading?: boolean;
  disabled?: boolean;
  block?: boolean;
  compact?: boolean;
  hideLabelUntil?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  className?: string;
  children: React.ReactNode;
};

type ButtonProps = BaseProps & {
  as?: 'button';
  type?: 'button' | 'submit';
  ref?: React.Ref<HTMLButtonElement>;
  onClick?: () => void;
};

type LinkProps = BaseProps & {
  as: 'link';
  href?: string;
  openInNewWindow?: boolean;
  onClick?: () => void;
};

const Button = (props: ButtonProps | LinkProps) => {
  const { color, iconPrefix, iconSuffix, loading, disabled, block, compact, hideLabelUntil, className } = props;

  const classes = baseClasses({
    color,
    iconPrefix: !!iconPrefix,
    iconSuffix: !!iconSuffix,
    loading,
    disabled,
    block,
    compact,
    hideLabelUntil,
    className,
    as: props.as,
  });

  if (props.as === 'link') {
    const { onClick, href, openInNewWindow } = props;

    return (
      <a
        className={classes}
        href={href}
        target={openInNewWindow ? '_blank' : undefined}
        rel="noreferrer"
        onClick={onClick}
      >
        <Content {...props} />
      </a>
    );
  }

  const { onClick, type = 'button' } = props;

  return (
    <button type={type} className={classes} onClick={onClick} disabled={disabled || loading}>
      <Content {...props} />
    </button>
  );
};

const Content = ({
  color,
  iconPrefix: IconPrefix,
  iconSuffix: IconSuffix,
  loading,
  hideLabelUntil,
  children,
}: BaseProps) => {
  return (
    <span className="relative block">
      <LoadingIndicator
        className={classNames(
          'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transition-opacity duration-300',
          {
            'opacity-0': !loading,
            'opacity-100': loading,
          }
        )}
        color={color === 'white' || color === 'gray' ? 'dark' : 'white'}
      />
      <span
        className={classNames('flex items-center justify-center gap-x-1.5 transition-opacity duration-300', {
          'opacity-100': !loading,
          'opacity-0': loading,
        })}
      >
        {IconPrefix && <IconPrefix className="text-xl" />}
        <span
          className={classNames('whitespace-nowrap px-1.5', {
            'max-sm:hidden max-sm:px-2.5': hideLabelUntil === 'sm',
            'max-md:hidden max-md:px-2.5': hideLabelUntil === 'md',
            'max-lg:hidden max-lg:px-2.5': hideLabelUntil === 'lg',
            'max-xl:hidden max-xl:px-2.5': hideLabelUntil === 'xl',
            'max-2xl:hidden max-2xl:px-2.5': hideLabelUntil === '2xl',
          })}
        >
          {children}
        </span>
        {IconSuffix && <IconSuffix className="text-xl" />}
      </span>
    </span>
  );
};

const baseClasses = cva('rounded-xl font-semibold text-lg transition-colors px-4', {
  variants: {
    color: {
      dark: 'bg-gray-900 text-white disabled:bg-gray-700 disabled:text-gray-200',
      gray: 'bg-gray-200 text-gray-900 disabled:bg-gray-200 disabled:text-gray-500',
      white: 'bg-white text-gray-900 disabled:bg-white disabled:text-gray-500',
      primary: 'bg-primary-500 text-white disabled:bg-primary-300 disabled:text-primary-100',
      green: 'bg-green-500 text-white disabled:bg-green-300 disabled:text-green-100',
      orange: 'bg-orange-500 text-white disabled:bg-orange-300 disabled:text-orange-100',
      red: 'bg-red-500 text-white disabled:bg-red-300 disabled:text-red-100',
    },
    iconPrefix: {
      true: null,
      false: null,
    },
    iconSuffix: {
      true: null,
      false: null,
    },
    loading: {
      true: 'cursor-default',
      false: null,
    },
    disabled: {
      true: 'cursor-default',
      false: null,
    },
    block: {
      true: 'w-full',
      false: null,
    },
    compact: {
      true: 'py-1.5',
      false: 'py-2.5',
    },
    hideLabelUntil: {
      sm: 'max-sm:px-2.5 max-sm:py-2.5',
      md: 'max-md:px-2.5 max-md:py-2.5',
      lg: 'max-lg:px-2.5 max-lg:py-2.5',
      xl: 'max-xl:px-2.5 max-xl:py-2.5',
      '2xl': 'max-2xl:px-2.5 max-2xl:py-2.5',
    },
    as: {
      button: null,
      link: 'inline-block',
    },
  },
  compoundVariants: [
    {
      loading: false,
      disabled: false,
      color: 'dark',
      className: 'hover:bg-gray-800',
    },
    {
      loading: false,
      disabled: false,
      color: 'gray',
      className: 'hover:bg-gray-300',
    },
    {
      loading: false,
      disabled: false,
      color: 'white',
      className: 'hover:bg-gray-200',
    },
    {
      loading: false,
      disabled: false,
      color: 'primary',
      className: 'hover:bg-primary-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'green',
      className: 'hover:bg-green-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'orange',
      className: 'hover:bg-orange-600',
    },
    {
      loading: false,
      disabled: false,
      color: 'red',
      className: 'hover:bg-red-600',
    },
  ],
  defaultVariants: {
    color: 'dark',
    iconPrefix: false,
    iconSuffix: false,
    loading: false,
    disabled: false,
    block: false,
    compact: false,
    hideLabelUntil: null,
    as: 'button',
  },
});

export default Button;
