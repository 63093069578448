import * as Sentry from '@sentry/react';
import { useTranslation } from 'lib/translation/index';
import type { ReactNode } from 'react';
import { Component } from 'react';

import Alert from '../ui/Alert';

export type ErrorBoundaryProps = {
  silent?: boolean;
  children?: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }
  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
    Sentry.captureException(error);
  }
  render() {
    if (this.state.hasError && this.props.silent) {
      return null;
    }
    if (this.state.hasError) {
      return <GenericError />;
    }
    return this.props.children;
  }
}

const GenericError = () => {
  const { translate } = useTranslation();

  return (
    <Alert title={translate('error.generic.title')} description={translate('error.generic.description')} type="error" />
  );
};
